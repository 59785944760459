<script>
import { computed, nextTick } from 'vue'
import modal from '/~/core/mdl'
import { useTravel } from '/~/extensions/travel/composables'
import { getProviderDefaultUrl } from '/~/utils/cdn'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs-v3.vue'
import EwDetailsLayout from '/~/components/ewallet/details/ew-details.vue'
import EwGallery from '/~/components/ewallet/gallery/ew-gallery.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useCms } from '/~/composables/cms/use-cms'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'
export default {
  name: 'travel-packages-details',
  components: {
    EwGallery,
    EwDetailsLayout,
    BaseButton,
    BaseIcon,
    BaseTabs,
    BaseTab,
  },
  props: {
    slug: {
      type: [String, Number],
      default: null,
    },
  },
  setup() {
    const {
      routeState,
      fetchTravelPackage,
      isCashbackReturn,
      isPointsReturn,
      pointsReturnLabel,
    } = useTravel()
    const { isBupaTemplate } = useProvider()
    const { titleRef, titleFocus } = useUI()
    const { withAccessCheck } = useAccess()
    const { ewalletLabels } = useCms()
    const { translate } = useLocalization()

    function showTravelDetails() {
      modal.show('travel-details-rewards')
    }

    const defaultImage = computed(() =>
      isBupaTemplate.value ? getProviderDefaultUrl('bupa') : undefined
    )

    return {
      routeState,
      fetchTravelPackage,
      showTravelDetails,
      defaultImage,
      titleRef,
      titleFocus,
      withAccessCheck,
      ewalletLabels,
      isCashbackReturn,
      isPointsReturn,
      pointsReturnLabel,
      translate,
    }
  },
  data() {
    return {
      loading: true,
      // ready: false,
      packageDetails: null,
      message: '',
      trackingId: '',
      name: '',
      detailsVisible: false,
      showResortDetails: false,
      showTermsAndConditions: false,
    }
  },
  computed: {
    backRoute() {
      return {
        name: 'travel',
        ...this.routeState,
      }
    },
    gallery() {
      return this.packageDetails?.gallery?.map((item) => ({ url: item }))
    },
    inclusionsCleared() {
      return this.packageDetails?.inclusions.join(', ') ?? ''
    },
    ready() {
      const { packageDetails = {} } = this

      return (
        packageDetails !== undefined && Object.keys(packageDetails).length > 0
      )
    },
  },
  watch: {
    $route(to, from) {
      if (to.hash || from.hash) return

      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const { data } = await this.fetchTravelPackage(this.slug)

      this.packageDetails = data
      this.loading = false

      nextTick(() => {
        this.titleFocus()
      })
    },
    formSent(data) {
      this.trackingId = data.trackingId
      this.name = data.name

      modal.show('travel-enquire-confirm', {
        props: {
          packageDetails: this.packageDetails,
          name: this.name,
          trackingId: this.trackingId,
          ...(this.isBupaTemplate && {
            contacts: {
              name: 'My Holidays',
              phone: '1300006972',
            },
          }),
        },
      })
    },
    openTravelFormModal() {
      modal.show('travel-details-form', {
        props: {
          packageDetails: this.packageDetails,
        },
        on: {
          'form-sent': this.formSent,
        },
      })
    },
  },
}
</script>

<template>
  <ew-details-layout
    :loading="loading"
    :ready="!loading"
    :title="packageDetails && packageDetails.name"
    :back="backRoute"
    :placeholder-ratio="1"
    class="px-5"
  >
    <template #gallery>
      <div class="px-5 sm:px-0">
        <ew-gallery :gallery="gallery" :default-src="defaultImage" />
      </div>
    </template>
    <template #summary>
      <div v-if="packageDetails" class="px-5 sm:px-0">
        <div class="mb-[30px] mt-[15px] space-y-[15px]">
          <h2
            class="text-center text-2xl font-normal sm:text-left sm:text-3xl"
            v-html="packageDetails.destination"
          />
          <h3
            v-if="packageDetails.name"
            ref="titleRef"
            class="text-center text-2xl font-bold leading-tight sm:text-left sm:text-3xl"
            tabindex="-1"
            v-html="packageDetails.name"
          />
          <ul
            class="flex items-center justify-center space-x-[30px] text-eonx-neutral-600 sm:justify-start"
          >
            <li>
              <base-icon svg="plain/plane-v3" :size="20" />
            </li>
            <li>
              <base-icon svg="plain/bed-v3" :size="20" />
            </li>
            <li>
              <base-icon svg="plain/gift-v3" :size="20" />
            </li>
          </ul>
        </div>
        <div>Inclusions</div>
        <div
          v-if="inclusionsCleared"
          class="markup markup-travel"
          v-html="inclusionsCleared"
        />
        <div
          v-if="packageDetails.features"
          class="markup"
          v-html="packageDetails.features"
        />
        <base-tabs class="-mx-5 mt-[30px] sm:mx-0">
          <base-tab
            v-for="(tab, index) of packageDetails.contents"
            :key="index"
            :name="tab.heading"
            class="mx-5 sm:mx-0"
          >
            <div class="markup" v-html="tab.body" />
          </base-tab>
        </base-tabs>
        <div class="mt-[30px] flex items-start border-t pt-5">
          <div v-if="isCashbackReturn" class="shrink-0 text-primary">
            <base-icon svg="ewallet" size="3xl" />
          </div>
          <div v-if="isCashbackReturn" class="pl-5">
            <div class="mb-2.5 text-lg font-bold">
              When will I receive my $100
              {{ ewalletLabels.ewallet }} Cashback?
            </div>
            <p>
              After selecting and paying in full for your travel deal, your
              Cashback will be deposited to
              {{ ewalletLabels.yourEwallet }} within 60 days.
            </p>
          </div>
          <div v-else-if="isPointsReturn">
            <div class="mb-2.5 text-lg font-bold">
              When will I receive my
              {{ pointsReturnLabel }}?
            </div>
            <p>
              Your {{ pointsReturnLabel }} will be deposited into your
              {{ ewalletLabels.yourEwallet }} within 60 days of completing your
              travel deal.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap items-center justify-between">
          <base-button
            look="link"
            size="md"
            class="my-10"
            @click="showTravelDetails"
          >
            <span class="underline">
              Myholiday Travel Terms {{ translate('common.and') }} Privacy
              Policy
            </span>
          </base-button>

          <div
            class="order-1 mb-[30px] flex w-full max-w-[400px] items-center text-primary sm:order-none sm:mb-0 sm:max-w-56"
          >
            <img
              src="/images/bupa/travel/MyHoliday-TropicalTurquoise-RGB.png"
              alt="Myholiday Logo"
            />
          </div>
          <div class="sm:order-0 order-3 flex w-full justify-center sm:w-auto">
            <base-button
              class="w-full"
              @click="withAccessCheck({ callback: openTravelFormModal })"
            >
              Enquire now
            </base-button>
          </div>
        </div>
      </div>
    </template>
  </ew-details-layout>
</template>
